
import { reactive } from "vue";

const SelectedVariationOptions = reactive({

  selections: [],

  reset() {
    this.selections = {};
  },
  updateSelection(parent_id, selections) {
    this.selections[parent_id] = selections;
  },
});

const SelectedDates = reactive({

  dates: {},

  reset() {
    this.dates = {};
  },
  updateSelection(product_id, array) {
    array.forEach((date, index) => {
      array[index] = date.toISOString().split('T')?.[0] ?? null;
    });
    if (array && array?.filter(Boolean).length) {
      this.dates = array;
    }
  },
});

export { SelectedVariationOptions, SelectedDates };
