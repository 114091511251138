import { router } from "@inertiajs/vue3";
import { SelectedVariationOptions } from '@/Stores/SelectedVariationOptions.js';

function setInertiaOptions(options, overrides = {}) {
  let inertiaOptions = {
    preserveState: true,
    preserveScroll: true,
  };
  for (const [key, value] of Object.entries(options)) {
    if (['onBefore', 'onStart', 'onSuccess', 'onError', 'onFinish'].includes(key)) {
      if (typeof value === "function") {
        inertiaOptions[key] = value;
      } else {
        console.error("error:", key, "is not a function");
      }
    } else if (['replace', 'preserveState', 'preserveScroll'].includes(key)) {
      if (typeof value === "boolean") {
        inertiaOptions[key] = value;
      } else {
        console.error("error:", key, "is not a boolean");
      }
    }
  }
  inertiaOptions = { ...inertiaOptions, ...overrides };
  return inertiaOptions;
}

function cartAdd(productId, options = {}) {

  let amount = options.amount || 1,
      dates = options.dates || [],
      selections = options.selections || [],
      allselections = options.allselections || [],
      accessories = options.accessories || [],
      bundleitems = options.bundleitems || [],
      productType = options.productType || null,
      text = options.text || null,
      leasing = options.leasing,
      cnc = options.cnc || null;


  if (!productId) {
    return false;
  }

  let data = { productId, productType, selections, allselections, amount, accessories, bundleitems, leasing, dates, text, cnc };

  let inertiaOptions = setInertiaOptions(options);

  return router.post("/cart/add", data, inertiaOptions);
}

function cartUpdateAmount(rowId, amount, options = {}) {

  const data = { rowId, amount };

  let inertiaOptions = setInertiaOptions(options);

  return router.post("/cart/update", data, inertiaOptions);
}

function updateShippingAndPayment(shippingId, paymentId, options = {}) {

  const data = { shippingId, paymentId };

  let inertiaOptions = setInertiaOptions(options);

  return router.post("/cart/update", data, inertiaOptions);
}

function useCode(code, options = {}) {
  if (!code) {
    return false;
  }

  let points = options?.rewardPoints ?? 0;

  const data = { code, points };

  let inertiaOptions = setInertiaOptions(options);

  return router.post("/cart/code/use", data, inertiaOptions);
}

function removeCode(code, options = {}) {
  if (!code) {
    return false;
  }

  const data = { code };

  let inertiaOptions = setInertiaOptions(options);

  return router.post("/cart/code/remove", data, inertiaOptions);
}

function cartClear() {
  // TODO: tyhjennä cart ja resetoi sessio
}

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

// Deep merge with overwrite for objects
function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

function stripStyle(string) {
  if (string && typeof string === 'string') {
    string = string.replace(/style="[^"]*"/g, '').trim();
  }
  return string;
}


// TODO veke
function variationSelections() {
  return Object.values(SelectedVariationOptions.selections);
}

// TODO veke
function selectedProduct(product) {
  if (variationSelections().length == 1) {
    let variation = product.web_variations.data.find(item => item.id == variationSelections()[0]);
    if (variation) {
      product = variation;
    };
  }
  return product;
}

// TODO veke
function strip_tags(string) {
  if (string && typeof string === 'string') {
    string = string.replace(/<[^>]+>/g, '').replace(/&#\d+;/gm,function(s) {
      return String.fromCharCode(s.match(/\d+/gm)[0]);
    }).trim();
  }
  return string;
}

// TODO veke
function arrayIndexExists(array, index) {
  return (typeof array[index] !== 'undefined') ? true : null;
}

// TODO veke
function numberFormat(float = '') {
  if(!float) return '';
  if(typeof float !== 'string') float = float.toString();
  let posC = float.indexOf(',');
  if (posC === -1) return float;
  let posFS = float.indexOf('.');
  if (posFS === -1) return float.replace(/,/g, '.');
  return ((posC < posFS) ? (float.replace(/,/g, '')) : (float.replace(/\./g, '').replace(',', '.')));
}

export {
  cartAdd,
  cartUpdateAmount,
  updateShippingAndPayment,
  cartClear,
  useCode,
  removeCode,
  debounce,
  mergeDeep,
  variationSelections,
  selectedProduct,
  strip_tags,
  arrayIndexExists,
  numberFormat,
  stripStyle,
};
